<template>
  <v-card min-height="400px" color="#273142">
    <v-card-title class="card-header">
    <h3>Refunds List</h3>
    <v-spacer> </v-spacer>
    <div class="">
      <RefundModalForm />
    </div>
  </v-card-title>

  <v-card-text class="pa-0" flat>
    <v-data-table
      :headers="headers"
      :items="refunds"
      item-text="text"
      item-value="value"
      class="transparent"
      :color="ant"
      :loading="loading"
    > 
      <template v-slot:item.created_at="{ item }">
        <span>{{ formatDateMonth(item.created_at) }}</span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span> $ {{ item.order.amount }}</span>
      </template>
      <template v-slot:item.order_id="{ item }">
        <span>{{ item.order_id ? item.order_id : '__' }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip flat color="info" x-small v-if="item.status === 'pending'">{{item.status}}</v-chip>
        <v-chip flat color="warning" x-small v-if="item.status === 'declined'">{{item.status}}</v-chip>
        <v-chip flat :color="essayGreen" dark x-small v-if="item.status === 'resolved'">{{ item.status }}</v-chip>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from '@/mixins/colorMxn';
import timeZoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'RefundList',
  components: {
    RefundModalForm: () => import('./RefundModalForm'),
  },
  mixins: [colorMxn, timeZoneMxn],
  data() {
    return{
       headers: [
        { text: "Date", value: "created_at" },
        { text: "Amount", value: "amount" },
        { text: "Order Id", value: "order_id" },
        { text: "status", value: "status"},
      ],
      refunds: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  mounted() {
    this.getRefunds()
  }, 
  methods: {
    ...mapActions(['performGetActions']),
    async getRefunds() {
      this.loading = true;
      const fullpayload = {
        endpoint: `/refund/show/${this.getSession.customer.id}`
      }

      const response = await this.performGetActions(fullpayload);
      this.loading = false;
      if (response.status) {
        this.refunds = response.data.data;
      }

    }
  }
}
</script>

<style>
.essay-green {
  color:#038c43;
}

tbody tr {
  border-bottom: none;
}

.link {
  cursor: pointer;
  color: #0062DB;
  text-decoration: underline;
}
</style>